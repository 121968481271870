import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../Footer'
import { Link } from 'react-router-dom'

const ListedPreview = () => {
   
    return (
        <>
            <Navbar />
            <div className="new_page">
               
                <div className="list_preview pt-5 container col-lg-6 col-md-10 col-sm-12 mx-auto  mt-5  p-4 mb-5 d-flex flex-column justify-content-center align-items-center ">
                    <h2 className="text-center blue fw-bold">Thank you !</h2>
                    <p className='text-center'>For listing your car with <span className="blue">Carloca</span>, here's a preview of your listing below. To ammend your listing please visit your dashboard.</p>
                    <div className="card mt-4">
                        <img src="img/car1.jpg" className="w-100" alt="" data-bs-toggle="modal" data-bs-target="#exampleModal" />
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h5>Sport Car</h5>
                            </div>
                            <p className='m-0 sml'>Hatchback</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="d-flex p-0 mb-0">
                                    <li className="d-flex me-2"><i className="bi bi-list-nested me-1"></i><span className="sml">Automatic</span></li>
                                    <li className="d-flex me-2"><i className="bi bi-person me-1"></i><span className="sml">2</span></li>
                                </ul>
                                <h5 className="price mb-0">£80,000</h5>

                            </div>
                            <div className="description mt-4">
                                <ul className='p-0 px-3'>
                                    <li className='d-flex mb-3'>
                                        <i className="fa-solid fa-check me-2 pt-1"></i>
                                        <p className="m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, quod.</p>
                                    </li>
                                    <li className='d-flex mb-3'>
                                        <i className="fa-solid fa-check me-2 pt-1"></i>
                                        <p className="m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, quod.</p>
                                    </li>
                                    <li className='d-flex mb-3'>
                                        <i className="fa-solid fa-check me-2 pt-1"></i>
                                        <p className="m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, quod.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <Link to='/' className='btn btn-primary btn-sm'>Back To Home</Link>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content rounded-0">

                            <div className="modal-body py-1">
                                <div className="row p-2">
                                    <div className="col-7 p-1">
                                        <img className='w-100 h-100' src="img/Slide1.png" alt="" />
                                    </div>
                                    <div className="col-5 p-1">
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <img className='w-100' src="img/Slide2.png" alt="" />
                                            </div>
                                            <div className="col-12 ">
                                                <img className='w-100' src="img/Slide3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 py-1">
                                <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default ListedPreview