import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from '../Footer'
import SearchArea from './SearchArea'
import CarDetailSlider from './CarDetailSlider'

const Cardetail1 = () => {
    return (
        <>
            <Navbar />
            <div className="new_page">
                {/* <div className="main_banner "></div>
         <SearchArea/> */}
                <div className="detail_card detail_card1 container w-100 mt-5 py-5">
                    <h2 className='fw-bold text-center mb-3'>Car Details</h2>
                    <div className="col-lg-7 col-md-9 col-sm-11 mx-auto">
                        <div className="card border position-relative">
                            <CarDetailSlider />

                            <div className="card-body">
                                <div className="border rounded-3 py-3 px-2 mb-3">
                                    <ul className="p-0 d-flex justify-content-between m-0">
                                        <li>
                                            <h6 className="fw-bold">Make</h6>
                                            <p className='m-0'>Porshe</p>
                                        </li>
                                        <li>
                                            <h6 className="fw-bold">Model</h6>
                                            <p className='m-0'>Cayenne</p>
                                        </li>
                                        <li>
                                            <h6 className="fw-bold">Year</h6>
                                            <p className='m-0'>2022</p>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-center">
                                            <h6><div className="fa-solid fa-chevron-right"></div></h6>
                                        </li>
                                    </ul>

                                </div>

                                <h5>Description</h5>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In odio dolores eligendi totam quod odit! Necessitatibus, animi suscipit facere quos quaerat ipsa enim placeat, iste incidunt perspiciatis saepe corrupti sequi aliquid at. Alias accusantium fuga rem velit a dolorem ab.</p>

                                <div className="row justify-content-center align-items-center mt-3  blue">
                                    <div className=" col-lg-3 col-md-6 col-sm-6 d-flex align-items-center justify-content-center mb-2">
                                        <i className="bi bi-list-nested "></i>
                                        <p className='m-0 ps-1'>Automatic</p>
                                    </div>

                                    <div className=" col-lg-3 col-md-6 col-sm-6 d-flex align-items-center justify-content-center mb-2">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <p className='m-0 ps-1'>5 miles away</p>
                                    </div>

                                    <div className=" col-lg-3 col-md-6 col-sm-6 d-flex align-items-center justify-content-center mb-2">
                                        <i className="fa-solid fa-gauge-high"></i>
                                        <p className='m-0 ps-1'>67,434 miles</p>
                                    </div>

                                    <div className=" col-lg-3 col-md-6 col-sm-6 d-flex align-items-center justify-content-center mb-2">
                                        <i className="fa-solid fa-gas-pump"></i>
                                        <p className='m-0 ps-1'>Diesel</p>
                                    </div>
                                </div>

                                <h4 className="text-center mt-4">Offers Starting from <span className="blue">£80,000</span></h4>
                            </div>
                        </div>

                    </div>


                </div>

                <Footer />
            </div>
        </>
    )
}

export default Cardetail1