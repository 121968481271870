import React from 'react'
import Navbar from '../components/Navbar'

const PaymentDetails = () => {
  return (
    <>
      <Navbar />
      <div className="col-lg-6 col-md-9 col-sm-12 mx-auto px-3 py-5 mt-5 ">
        <div className="card border-0 payment_details">
          <div className="card-body">
            <h5 className='fw-bold mb-4'>Card details</h5>
            <ul className='p-0'>
              <li className='d-flex align-items-center mb-3'>
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <span><i className="fa-regular fa-user"></i></span>
                </div>
                <input type="text" placeholder='Name' />
              </li>
              <li className='d-flex align-items-center mb-3'>
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <span><i className="fa-solid fa-credit-card"></i></span>
                </div>
                <input type="text" placeholder='Card Details' />
              </li>
              <li className='d-flex align-items-center mb-3'>
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <span><i className="fa-regular fa-calendar-days"></i></span>
                </div>
                <input type="date" placeholder='Expiry' />
              </li>
              <li className='d-flex align-items-center mb-3'>
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <span> <i className="bi bi-shield-fill-check"></i></span>
                </div>
                <input type="text" placeholder='CVC' />
              </li>
              <div className="d-flex justify-content-center">
                <div className="col-1"></div>
                <div className='d-flex'><button className="btn btn-outline-primary rounded-pill">Confirm</button></div>
              </div>
            </ul>
          </div>
        </div>

      </div>
    </>
  )
}

export default PaymentDetails