import React from 'react'
import { Link } from 'react-router-dom'

const HowToUse = () => {
    return (
        <>
            <section className=" container d-flex flex-column py-3 py-md-5">
                <h2 className=" fw-bold mb-3">How to Use Carloca as a <span className="blue">Buyer</span></h2>

                <div className="d-flex w-100 justify-content-center how_use py-3 py-md-5 rounded-3">
                    <div className="row w-100">
                        <div className="col-md-3 col-sm-6">
                            <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                                <Link className='text-center'>
                                    <i className="fa-solid fa-search"></i>
                                    <h5 className='fw-bold mt-2'>Search</h5>
                                </Link>
                                <p className='text-center mb-0'>Using our filter and search system you will be able to look for your perfect car with the specificatoins you want and at a price point that suits you.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                                <Link className='text-center'>
                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                    <h5 className='fw-bold mt-2'>Send Offers</h5>
                                </Link>
                                <p className='text-center mb-0'>Once you've found your perfect car, you will be able to send offers to the seller, they can either accept or deny the request.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                                <Link className='text-center'>
                                    <i className="fa-solid fa-lock-open"></i>
                                    <h5 className='fw-bold mt-2'>Unlock</h5>
                                </Link>
                                <p className='text-center mb-0'>When the lister accepts your offer, you will unlock the listers contact details, and a credit will be deducted. If the seller does not accept the offer, the credit won't be deducted.</p>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                                <Link className='text-center'>
                                    <i className="fa-solid fa-comment-dots"></i>
                                    <h5 className='fw-bold mt-2'>Chat</h5>
                                </Link>
                                <p className='text-center mb-0'>You'll be able to talk to the lister either by phone or email,depending on what contact detail the seller has provided.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default HowToUse