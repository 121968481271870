import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import About from "../About";
import Navbar from "../components/Navbar";
import Footer from "../Footer";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// import SmallSearch from "../components/SmallSearch";
import axios from "axios";

const Login = () => {
  const navigator=useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dealerEmail, setDealerEmail] = useState("");
  const [dealerPassword, setDealerPassword] = useState("");

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isDealerPassShow, setisDealerPassShow] = useState(false);

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const togglePasswordDealer = () => {
    setisDealerPassShow(!isDealerPassShow);
  };
  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address", optionToast);
      return;
    }
    fetchData();
  };
  const handleDealerLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!dealerEmail || !emailRegex.test(dealerEmail)) {
      toast.error("Please enter a valid Dealer email address", optionToast);
      return;
    }
    loginDealer();
    
  };
  const saveDataToSessionStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      sessionStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to sessionStorage:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/login/user",
        {
          email: email,
          password: password,
        }
      );
      // console.log(response?.data?.data);
      if(response?.data?.status){
        saveDataToSessionStorage("userData", response?.data?.data);
        showAlert(
          response?.data?.message,
          response?.data?.data?.id,
          response?.data?.status
        );
      }else{
        toast.error(response?.data?.message,optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const loginDealer = async () => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/login/dealer",
        {
          email: dealerEmail,
          password: dealerPassword,
        }
      );
      // console.log(response?.data?.data);
      if(response?.data?.status){
        saveDataToSessionStorage("userData", response?.data?.data);
        showAlert(
          response?.data?.message,
          response?.data?.data?.id,
          response?.data?.status
        );
      }else{
        toast.error(response?.data?.message,optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const showAlert = (data, id, show) => {
    
    if (show) {
      toast.success(data,optionToast);
      setTimeout(() => {
        navigator('/profilesetting');
      }, 1500);
    }else{
      toast.error(data,optionToast);
    }
  };
  
  return (
    <>
      <div className="account_details">
        <Navbar />
        {/* <SmallSearch/> */}
        <section className="account_login mt-5 pt-3 pb-4 w-100">
          <div className="container  ">
            <div className="row">
              <div className="col-md-6">
                <h2 className="text-center mt-3 fw-bold fs-6">
                  Login / Sign Up Here
                </h2>
                <div className="card mt-3 border-0">
                  <div className="card-body py-4 px-4">
                    <h4 className=" mb-3  fs-5 fw-semibold">
                      Please enter details
                    </h4>
                    <form action="">
                      <div className="mb-4 position-relative">
                        <input
                          type="email"
                          placeholder="Email Address"
                          className="w-100 "
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      <div className="mb-3 position-relative">
                        <input
                          placeholder="Password"
                          type={showPasswordConfirm ? "text" : "password"}
                          className="w-100"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i className="fa-solid fa-lock"></i>
                        <div
                          className="position-absolute end-0 top-50 translate-middle-y"
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={togglePasswordVisibilityConfirm}
                        >
                          {showPasswordConfirm ? "Hide" : "Show"}
                        </div>
                      </div>
                      <Link to="/forgetpassword" className="blue">
                        Forgotten Password ?
                      </Link>
                      <div>
                        {/* Use a button type for form submission */}
                        <button
                          type="button"
                          className="btn btn-primary w-100 py-3 mt-3 fw-bold"
                          onClick={handleLogin}
                        >
                          LOGIN
                        </button>
                      </div>
                      {/* <div> <Link className="btn btn-primary w-100 py-3 mt-3 fw-bold">LOGIN</Link></div> */}
                      <div className="mt-3 other_media d-flex justify-content-center">
                        <span className="text-center">Or Continue With</span>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <Link
                          to="/register"
                          className="btn btn-outline-primary w-100 py-3 fw-bold"
                        >
                          SIGN UP
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="text-center mt-3 fw-bold fs-6">Dealer Login</h2>
                <div className="card mt-3 border-0">
                  <div className="card-body py-4 px-4">
                    <h4 className=" mb-3 fs-5 fw-semibold">
                      Please enter details
                    </h4>
                    <form action="">
                      <div className="mb-4 position-relative">
                        <input
                          type="email"
                          placeholder="Email Address"
                          className="w-100 "
                          value={dealerEmail}
                          onChange={(e) => setDealerEmail(e.target.value)}
                        />
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      <div className="mb-3 position-relative">
                        <input
                          type={isDealerPassShow ? "text" : "password"}
                          placeholder="Password"
                          className="w-100"
                          value={dealerPassword}
                          onChange={(e) => setDealerPassword(e.target.value)}
                        />
                        <i className="fa-solid fa-lock"></i>
                        <div
                          className="position-absolute end-0 top-50 translate-middle-y"
                          style={{ cursor: "pointer", marginRight: 10 }}
                          onClick={togglePasswordDealer}
                        >
                          {isDealerPassShow ? "Hide" : "Show"}
                        </div>
                      </div>
                      <Link to="/forgetpassword" className="blue">
                        Forgotten Password ?
                      </Link>
                      <div>
                        {/* Use a button type for form submission */}
                        <button
                          type="button"
                          className="btn btn-primary w-100 py-3 mt-3 fw-bold"
                          onClick={handleDealerLogin}
                        >
                          LOGIN
                        </button>
                      </div>

                      <div className="mt-3 other_media d-flex justify-content-center">
                        <span className="text-center">Or Continue With</span>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <Link
                          to="/dealer-register"
                          className="btn btn-outline-primary w-100 py-3 fw-bold"
                        >
                          DEALER REGISTRATION
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
