import React from "react";

import {  useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../Footer";

function TermsConditions() {
    const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <>
      <Navbar />
      <div className="row border-0 justify-content-center mt-5">
        <div className="col-md-10 col-11">
          <h4 className="text-center fs-3 my-4 thank_heading mt-5">
            Our Terms & Conditions
          </h4>
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">1. Introduction</h2>
            <p>
              Welcome to carloca. These terms and conditions outline the rules
              and regulations for using our website and services, including
              listing cars for sale and finding cars for purchase.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">2. Listing Cars for Sale</h2>
            <p>
              When listing a car for sale on carloca, you agree to provide
              accurate and complete information about the vehicle. You must
              ensure that all details, including the car's condition, price, and
              other relevant information, are truthful.
            </p>
            <p>
              carloca reserves the right to remove any listings that are deemed
              inappropriate, inaccurate, or in violation of our policies.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">3. Finding Cars for Purchase</h2>
            <p>
              carloca provides a platform for users to find cars for purchase.
              We do not guarantee the accuracy or reliability of the information
              provided by sellers. It is the buyer's responsibility to verify
              the details of the car and conduct due diligence before making a
              purchase.
            </p>
            <p>
              Any transactions or agreements made between buyers and sellers are
              solely between the parties involved. carloca is not responsible
              for any disputes, issues, or losses arising from such
              transactions.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">4. User Responsibilities</h2>
            <p>
              By using carloca, you agree to use the platform in compliance with
              all applicable laws and regulations. You are responsible for
              maintaining the confidentiality of your account information and
              for all activities that occur under your account.
            </p>
            <p>
              You agree not to use carloca for any unlawful purposes or in a
              manner that could damage, disable, or impair the platform.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">5. Limitation of Liability</h2>
            <p>
              carloca shall not be liable for any indirect, incidental, special,
              or consequential damages arising from the use of our platform. We
              do not guarantee the availability, reliability, or accuracy of the
              services provided.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">6. Changes to Terms</h2>
            <p>
              carloca reserves the right to modify these terms and conditions at
              any time. Any changes will be posted on this page, and it is your
              responsibility to review the terms periodically. Your continued
              use of the platform constitutes acceptance of any changes.
            </p>
          </section>

          <section className="mb-4">
            <h2 className="fs-5 fw-bold">7. Contact Us</h2>
            <p>
              If you have any questions or concerns about these terms and
              conditions, please contact us at{" "}
              <a className="blue" href="mailto:support@carloca.com">
                support@carloca.com
              </a>
              .
            </p>
          </section>
          <button className="btn btn-primary my-4" onClick={handleBackClick}>
            Back 
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default TermsConditions;
