import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import About from "../About";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import Footer from "../Footer";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgetPassword = () => {
 const navigate= useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userid, setUserid] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [userOTP, setUserOtp] = useState("");
  const [processing, setProcessing] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const sendOTP = () => {
    setProcessing(true);
    if (!showOtpInput) {
      sendOtpData();
    } else {
      if (userOTP === parseInt(otp)) {
        setShowPasswordInput(true);
        setProcessing(false);
        toast.success("OTP Verified",optionToast);
        setShowOtpInput(false);
      } else {
        toast.error("Invalid otp", optionToast);
        setProcessing(false);
      }
    }
  };

  const sendOtpData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/user/email/validation",
        {
          email: email,
        }
      );

      // console.log(response?.data);
      if (response?.data?.status) {
        setUserOtp(response?.data?.otp);
        toast.success(response?.data?.message, optionToast);
        setUserid(response?.data?.data?.id);
        setShowOtpInput(true);
      } else {
        toast.error(response?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };

  const updatePassword = () => {
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        toast.error(
          "Password does not match the confirmation password",
          optionToast
        );
        setProcessing(false);
        return;
      } else {
        saveData();
      }
    } else {
      toast.error("Password is required!", optionToast);
      setProcessing(false);
      return;
    }
  };
  const saveData = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/user/update/password",
        {
          password: password,
          user_id: userid,
        }
      );
      // console.log(firstName + " " + lastName);

      console.log(response?.data);
      if (response?.data?.status) {
        toast.success(response?.data?.message, optionToast);

        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setOtp("");
        setShowOtpInput(false);
        setShowPasswordInput(false);
        navigate('/login');
      } else {
        toast.error(response?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
    setProcessing(false);
  };

  return (
    <>
      <div className="account_details">
        <Navbar />
        {/* <SmallSearch/> */}
        <section className="account_login mt-5 pt-5 pb-4 w-100">
          <div className="container">
            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
              <h2 className="text-center fw-bold">Forget Your Password</h2>
              <div className="card mt-3 border-0">
                <div className="card-body py-4 px-4">
                  <h4 className=" mb-3">Forgotten Password</h4>
                  <form action="">
                    <div className="mb-4 position-relative">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="w-100  "
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                    {showOtpInput && (
                      <div className="mb-4 position-relative">
                        <input
                          type="text"
                          placeholder="Enter OTP*"
                          className="w-100"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <i className="fa fa-key"></i>
                      </div>
                    )}
                    {showPasswordInput && (
                      <div>
                        <div className="mb-3 position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password*"
                            className="w-100"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i className="fa-solid fa-lock"></i>
                          <div
                            className="position-absolute end-0 top-50 translate-middle-y"
                            style={{ cursor: "pointer", marginRight: 10 }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </div>
                        </div>
                        <div className="mb-3 position-relative">
                          <input
                            type={showPasswordConfirm ? "text" : "password"}
                            placeholder="Confirm Password*"
                            className="w-100"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <i className="fa-solid fa-lock"></i>
                          <div
                            className="position-absolute end-0 top-50 translate-middle-y"
                            style={{ cursor: "pointer", marginRight: 10 }}
                            onClick={togglePasswordVisibilityConfirm}
                          >
                            {showPasswordConfirm ? "Hide" : "Show"}
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      {showPasswordInput && (
                        <button
                          type="button"
                          onClick={updatePassword}
                          disabled={processing}
                          className="btn btn-primary w-100 py-3 mt-3 fw-bold"
                        >
                          {showPasswordInput ? "UPDATE" : "SEND OTP"}
                        </button>
                      )}
                      {!showPasswordInput && (
                        <button
                          type="button"
                          onClick={sendOTP}
                          disabled={processing}
                          className="btn btn-primary w-100 py-3 mt-3 fw-bold"
                        >
                          {showOtpInput ? "VERIFY OTP" : "SEND OTP"}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default ForgetPassword;
