import React from 'react'

const About = () => {
  return (
   <>
    <section className="about container d-flex flex-column  pb-2 pt-2 pt-md-5 pb-md-5">
        <div className="row justify-content-center  ">
        <div className="col-lg-7 col-sm-6  p-md-3">
              
           <div className=''>
            <h2 className=" pb-3 fw-bold">About <span className='blue'>Carloca</span></h2>
           <p className='m-0'>Our premier online platform is for car enthusiasts and sellers. We understand the importance of trust, great offers and finding cars with certain specifications.</p>
            <p className='m-0'>That's why our platform is designed to offer a straightforward, secure, and efficient experience like no other</p>
           </div>
           <div className=" mt-3 mb-3">
            <h5 className='fw-bold'>Tired of <span className="blue">cold calls</span> and <span className="blue">time wasters?</span></h5>
            <p className='m-0'>Carloca is the genuine platform addresses just that by using a credit system which the lister has full control over. </p>
            <p className='m-0'>Whether you're looking to invest in a new car or position yours for sale, Carloca stands as your reliable partner.</p>
           </div>

            </div>
            <div className="offset-lg-1 col-lg-4 col-sm-6  p-md-3">
                <img src="img/how_use1.jpg.png"  className="w-100 rounded-2 h-100 mb-3" alt="" />
            </div>
            
            
        </div>
     </section>
   </>
  )
}

export default About