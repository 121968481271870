import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Heart from "react-heart";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import CryptoJS from "crypto-js";
import axios from "axios";
const FilterCarData = () => {
  const navigator = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [fminPrice, setminPrice] = useState(0);
  const [fmaxPrice, setmaxPrice] = useState(0);
  const [fdistance, setDistance] = useState("");
  const [fname, setName] = useState("");
  const [fmodel, setModel] = useState("");
  const [model_varient, setModelVariant] = useState("");
  const [fmodelVariantData, setModelVariantsData] = useState([]);
  // const [fbodyType, setBodyType] = useState("");
  const [modelData, setModelData] = useState([]);
  const [fbody_type, setfBodyType] = useState("");

  const [makeData, setMakeData] = useState([]);
  const [grearBoxs, setGearBoxs] = useState([]);
  const [allColors, setAllColors] = useState([]);
  const [bodTypes, setBodyTypes] = useState([]);
  const [fminyear, setMinYear] = useState("");
  const [fmaxyear, setMaxYear] = useState("");
  const [fminMileage, setminMileage] = useState("");
  const [fmaxMileage, setmaxMileage] = useState("");
  const [ffuelType, setFuelType] = useState([]);
  const [fgearbox, setGearbox] = useState([]);
  const [fcolors, setColors] = useState([]);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [isShowBack, setIsShowBack] = useState(false);
  const [engineSize, setEngineSize] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filteredData, setfilteredData] = useState([]);
  const distance = queryParams.get("distance");
  const minPrice = queryParams.get("min_price");
  const maxPrice = queryParams.get("max_price");
  const name = queryParams.get("make");
  const model = queryParams.get("model");
  const qmodel_variant = queryParams.get("model_variant");
  // alert(qmodel_variant)
  const body_type = queryParams.get("body_type");
  const fuelType = queryParams.get("fuel_type");
  const gearbox = queryParams.get("gearbox");
  const colors = queryParams.get("colors");
  const minMileage = queryParams.get("min_mileage");
  const maxMileage = queryParams.get("max_mileage");
  const minyear = queryParams.get("minYear");
  const maxyear = queryParams.get("maxYear");
  const engine_size = queryParams.get("engine_size");
  useEffect(() => {
    setDistance(distance);
    setminPrice(minPrice);
    setmaxPrice(maxPrice);
    setName(name);
    setModel(model);
    setModelVariant(qmodel_variant);

    setfBodyType(body_type);
    setFuelType(fuelType);

    setGearbox(gearbox);
    setColors(colors);
    setminMileage(minMileage);
    setmaxMileage(maxMileage);
    setEngineSize(engine_size);
    setMinYear(minyear);
    setMaxYear(maxyear);
    getModal(name);
    getModalVarient(model);
    getBodyType(model);

    // alert(maxPrice);
  }, []);
  const [likeStatus, setLikeStatus] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const [loading, setLoading] = useState(false);

  const startYear = 2014;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  const mileOptions = [];
  for (let mile = 5; mile <= 12; mile++) {
    mileOptions.push(mile);
  }
  const mmileOptions = [];
  for (let mile = 12; mile <= 20; mile++) {
    mmileOptions.push(mile);
  }

  const checkIfMobile = () => {
    const mediaQuery = window.matchMedia("(max-width: 840px)");

    if (mediaQuery.matches) {
      setIsShowFilter(false);
    } else {
      setIsShowFilter(true);
    }
  };

  // window.addEventListener("resize", checkIfMobile);
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  function commaSeparatedStringToArray(value) {
    if (!value) return [];
    return value.split(",").map((item) => item.trim());
  }
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");
  var gearBoxData = null;
  var fuelTypeData = null;
  var colorData = null;
  if (gearbox !== null) {
    gearBoxData = commaSeparatedStringToArray(gearbox);
  }
  if (fuelType !== null) {
    fuelTypeData = commaSeparatedStringToArray(fuelType);
  }
  if (colors !== null) {
    colorData = commaSeparatedStringToArray(colors);
  }

  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const searchFilterApi = async () => {
    setLoading(true);
    try {
      const search_value1 = {
        distance: distance ? distance : "",
        min_price: minPrice ? minPrice : "",
        max_price: maxPrice ? maxPrice : "",
        make: name ? name : "",
        model: model ? model : "",
        model_variant: model_varient ? model_varient : qmodel_variant,
        body_type: fbody_type ? fbody_type : "",
        fuelTypeData: fuelTypeData ? fuelTypeData : [],
        gearBoxData: gearBoxData ? gearBoxData : [],
        colorData: colorData ? colorData : [],
        transmission_type: gearbox ? gearbox : "",
        min_mileage: minMileage ? minMileage : "",
        max_mileage: maxMileage ? maxMileage : "",
        minyear: minyear ? minyear : "",
        maxyear: maxyear ? maxyear : "",
        engine_size: engine_size ? engine_size : "",
        user_id: storedUserPreferences?.id,
      };
      console.error(search_value1);
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/search2",
        search_value1
      );

      if (response?.data?.status) {
        const carsData = response?.data?.data;
        console.log(response?.data);
        const initialLikeStatus = carsData.reduce((status, car) => {
          status[car.id] = car.isFav;
          return status;
        }, {});
        setLikeStatus(initialLikeStatus);
        setfilteredData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    searchFilterApi();
    checkIfMobile();
  }, []);
  function convertCcToLiters(cc) {
    if(cc>0){

      return (cc / 1000).toFixed(1);
    }else{
      return 0;
    }
  }
  const filterCars = async () => {
    try {
      const search_value = {
        distance: fdistance,
        min_price: fminPrice,
        max_price: fmaxPrice,
        make: fname,
        model: fmodel,
        model_variant: model_varient,
        body_type: fbody_type,
        fuelTypeData: ffuelType !== "" ? ffuelType : [],
        gearBoxData: fgearbox !== "" ? fgearbox : [],
        colorData: fcolors !== "" ? fcolors : [],
        transmission_type: fgearbox,
        min_mileage: fminMileage,
        max_mileage: fmaxMileage,
        minyear: fminyear,
        maxyear: fmaxyear,
        engine_size: engineSize,
        user_id: storedUserPreferences?.id,
      };
      // console.warn(search_value);
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/search2",
        search_value
      );
      // console.log(response);
      if (response?.data?.status) {
        const carsData = response?.data?.data;
        // console.log(response?.data);
        const initialLikeStatus = carsData.reduce((status, car) => {
          status[car.id] = car.isFav;
          return status;
        }, {});
        setIsShowBack(true);
        setLikeStatus(initialLikeStatus);
        setfilteredData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const likeDislike = async (user_id, card_id) => {
    try {
      const responsed = await axios.post(
        "https://trustedcars.in/api/public/api/like-dislike",
        {
          user_id: user_id,
          car_id: card_id,
        }
      );
      // console.log(responsed);
      if (responsed?.data?.status) {
        toast.success(responsed?.data?.message, optionToast);
      } else {
        toast.error(responsed?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const handleLike = (cardId) => {
    if (storedUserPreferences !== null) {
      setLikeStatus((prevStatus) => ({
        ...prevStatus,
        [cardId]: !prevStatus[cardId],
      }));
      likeDislike(storedUserPreferences?.id, cardId);
    } else {
      toast.warning("Please login to add favorite car!", optionToast);
    }
  };
  useEffect(() => {
    getMake();
    getGearbox();
    getColors();
    // getFuelTypes();
  }, []);

  const getModal = async (companyName) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/model",
        { make: companyName }
      );
      if (response?.data?.status) {
        setModelData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getModalVarient = async (model) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/model-variant",
        { model: model }
      );
      if (response?.data?.status) {
        setModelVariantsData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getBodyType = async (model) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/body-type",
        { model: model }
      );
      if (response?.data?.status) {
        // console.log(response?.data?.data)
        setBodyTypes(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function getSelectedCheckboxValuesAsQueryString(name) {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}"]:checked`
    );
    let values = [];
    checkboxes.forEach((checkbox) => {
      return values.push(checkbox.value);
    });
    return values;
  }
  function updateURL() {
    setGearbox(getSelectedCheckboxValuesAsQueryString("gearbox[]"));
    setFuelType(getSelectedCheckboxValuesAsQueryString("fueltype[]"));
    setColors(getSelectedCheckboxValuesAsQueryString("colors[]"));
  }
  const getMake = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/make"
      );
      if (response?.data?.status) {
        // console.log(response?.data?.data, "make")
        setMakeData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getGearbox = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/gearbox"
      );
      if (response?.data?.status) {
        setGearBoxs(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  // const getFuelTypes = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://trustedcars.in/api/public/api/cars/fuel"
  //     );
  //     if (response?.data?.status) {
  //       setFuelTypes(response?.data?.data);
  //     }
  //   } catch (error) {
  //     console.log("An error occurred while fetching data.", error);
  //   }
  // };
  const getColors = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/colors"
      );
      if (response?.data?.status) {
        setAllColors(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  
  const backtolist = () => {
    setDistance("");
    setFuelType([]);
    setGearbox([]);
    setMaxYear("");
    setMinYear("");
    setName("");
    setModel("");
    setminMileage("");
    setmaxMileage("");
    setmaxPrice(100000);
    setminPrice(0);

    if (distance) {
      searchFilterApi();
    } else {
      filterCars();
    }
    navigator("/FilterCarData");
  };
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  const generateOptions = () => {
    const options = [];
    for (let i = 1.1; i <= 10; i += 0.1) {
      options.push(i.toFixed(1)); // Convert to string with one decimal place
    }
    return options;
  };
  return (
    <>
      <Navbar />
      <div className="new_page">
        <div className="approved_cars all_listings sml-card container-fluid mt-3 py-md-5 py-3 ">
          <div className="row">
            <div className="col-12 my-3 d-flex d-lg-none  justify-content-center mt-md-2 mt-5">
              <div className="row w-100">
                <div className="col-6">
                  <button
                    onClick={() => setIsShowFilter(!isShowFilter)}
                    className="btn w-100 d-flex justify-content-center me-4 align-items-center btn-outline-primary rounded-0"
                  >
                    FILTER
                    <i
                      className="fa-solid fa-filter "
                      style={{ marginLeft: "2rem" }}
                    ></i>
                  </button>
                </div>
                <div className="col-6">
                  <Link
                    to="/"
                    className="btn w-100 d-flex justify-content-center me-4 align-items-center btn-outline-primary rounded-0"
                  >
                    HOME{" "}
                    <i
                      className="fa-solid fa-home "
                      style={{ marginLeft: "2rem" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            {isShowFilter && (
              <div className="col-lg-3 mt-0 mb-4 mt-md-4 mb-md-1  ">
                <h5 className="fw-semibold fs-5">Filters</h5>

                <div className="card rounded-2 py-3 mt-md-4 mt-0 pb-0 mt-1">
                  <div className="card-body px-0 d-flex flex-column align-items-center pt-0">
                    <div className="row smallfilter w-100">
                      {/* make */}
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fname}
                          onChange={(e) => {
                            setName(e.target.value);
                            getModal(e.target.value);
                          }}
                        >
                          <option  value="">
                            Make
                          </option>
                          {makeData.map((item, index) => (
                            <option key={index} value={item?.make}>
                              {item?.make}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fmodel}
                          onChange={(e) => {
                            setModel(e.target.value);
                            getModalVarient(e.target.value);
                            getBodyType(e.target.value);
                          }}
                        >
                          <option  value="">
                            Model
                          </option>
                          {modelData.map((item, index) => (
                            <option key={index} value={item?.model}>
                              {item?.model}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={model_varient}
                          onChange={(e) => setModelVariant(e.target.value)}
                        >
                          <option  value="">
                            Model Variant
                          </option>
                          {fmodelVariantData.map((item, index) => (
                            <option key={index} value={item?.model_varient}>
                              {item?.model_varient}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fmaxMileage}
                          onChange={(e) => setmaxMileage(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Max Mileage
                          </option>
                          {mmileOptions.map((mile) => (
                            <option key={mile} value={mile}>
                              Upto {mile} Miles
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fminyear}
                          onChange={(e) => setMinYear(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Min Year
                          </option>
                          {years.map((yearl) => (
                            <option key={yearl} value={yearl}>
                              {yearl}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-6 mb-2">
                        <select
                          className="form-select"
                          value={fmaxyear}
                          onChange={(e) => setMaxYear(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Max Year
                          </option>
                          {years.map((yearl) => (
                            <option key={yearl} value={yearl}>
                              {yearl}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fminPrice}
                          onChange={(e) => setminPrice(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Min Price
                          </option>
                          <option value="0">£0</option>
                          <option value="5000">£5,000</option>
                          <option value="10000">£10,000</option>
                          <option value="20000">£20,000</option>
                          <option value="30000">£30,000</option>
                          <option value="40000">£40,000</option>
                          <option value="50000">£50,000</option>
                          <option value="60000">£60,000</option>
                          <option value="70000">£70,000</option>
                          <option value="80000">£80,000</option>
                          <option value="90000">£90,000</option>
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fmaxPrice}
                          onChange={(e) => setmaxPrice(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Max Price
                          </option>
                          <option value="5000">£5,000</option>
                          <option value="10000">£10,000</option>
                          <option value="20000">£20,000</option>
                          <option value="30000">£30,000</option>
                          <option value="40000">£40,000</option>
                          <option value="50000">£50,000</option>
                          <option value="60000">£60,000</option>
                          <option value="70000">£70,000</option>
                          <option value="80000">£80,000</option>
                          <option value="90000">£90,000</option>
                          <option value="100000">£100,000</option>
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fdistance}
                          onChange={(e) => setDistance(e.target.value)}
                          aria-label="Default select example"
                        >
                          <option value="" >
                            Distance
                          </option>
                          <option value="10000">National</option>
                          <option value="10">Within 10 miles</option>
                          <option value="20">Within 20 miles</option>
                          <option value="30">Within 30 miles</option>
                          <option value="40">Within 40 miles</option>
                          <option value="50">Within 50 miles</option>
                          <option value="60">Within 60 miles</option>
                          <option value="70">Within 70 miles</option>
                          <option value="80">Within 80 miles</option>
                          <option value="90">Within 90 miles</option>
                          <option value="100">Within 100 miles</option>
                          <option value="200">Within 200 miles</option>
                        </select>
                      </div>
                      <div className="col-6 mb-3">
                        <select
                          className="form-select"
                          value={fbody_type}
                          onChange={(e) => setfBodyType(e.target.value)}
                        >
                          <option value="" >
                            Body Type
                          </option>
                          {bodTypes.map((item, index) => (
                            <option key={index} value={item?.body_type}>
                              {item?.body_type}
                            </option>
                          ))}
                        </select>
                      </div>

                      {showDiv && (
                        <div className="col-12 mb-4">
                          <div className="row more_filter">
                            <div className="card mb-3">
                              <div className="card-body">
                                <h5 className="fs-6 mb-3 fw-semibold">
                                  Gearbox
                                </h5>
                                <div className="d-flex justify-content-between">
                                  {grearBoxs.map((item, index) => (
                                    <div class="form-check d-flex align-items-center">
                                      <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        onChange={updateURL}
                                        name="gearbox[]"
                                        value={item?.transmission_type}
                                        key={index}
                                      />
                                      <label
                                        className="form-check-label text-capitalize mb-0 pb-0"
                                        for={`gearbox ` + index}
                                      >
                                        {item?.transmission_type}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="card mb-3">
                              <div className="card-body">
                                <h5 className="fs-6 mb-1 fw-semibold">
                                  Fuel Type
                                </h5>
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="petrol"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Petrol
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="diesel"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Diesel
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="bi fuel"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Bi Fuel
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="elecric"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Elecric
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="diesel hybrid"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Diesel Hybrid
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 col-sm-4 mt-3">
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          className="form-check-input me-2"
                                          type="checkbox"
                                          onChange={updateURL}
                                          name="fueltype[]"
                                          value="petrol hybrid"
                                        />
                                        <label
                                          className="form-check-label mb-0 pb-0"
                                          for="defaultCheck1"
                                        >
                                          Petrol Hybrid
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card mb-3">
                              <div className="card-body">
                                <h5 className="fs-6 mb-3 fw-semibold">
                                  Engine Size
                                </h5>
                                <div className="col-12">
                                  <div className="form-group">
                                    <select
                                      onChange={(e) => {
                                        setEngineSize(e.target.value);
                                      }}
                                      value={engineSize}
                                      className="form-select"
                                      name="engine_size"
                                    >
                                      <option value="">Select Size</option>
                                      {generateOptions().map((esize) => (
                                        <option key={esize} value={esize}>
                                          {esize}L
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card ">
                              <div className="card-body">
                                <h5 className="fs-6 mb-1 fw-semibold">
                                  Colour
                                </h5>
                                <div className="col-12">
                                  <div className="row">
                                    {allColors.map((item, index) => (
                                      <div className="col-6 col-sm-4 mt-3">
                                        <div class="form-check d-flex align-items-center">
                                          <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            onChange={updateURL}
                                            name="colors[]"
                                            value={item?.color}
                                            key={index}
                                          />
                                          <label
                                            className="form-check-label text-capitalize mb-0 pb-0"
                                            for={`color ` + index}
                                          >
                                            {item?.color}
                                          </label>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-12 d-flex justify-content-end">
                        <button
                          onClick={filterCars}
                          className="btn btn-primary  w-100 fw-bold"
                          style={{ letterSpacing: "2px" }}
                        >
                          FILTER
                        </button>
                      </div>

                      <div className="col-12 d-flex mt-2 justify-content-between more_options">
                        <Link
                          className="nav-link fs-7 text-decoration-none text-dark"
                          to="/FilterCarData"
                        >
                          RESET FILTER
                        </Link>
                        <button
                          type="button"
                          className="nav-link fs-7"
                          onClick={toggleDiv}
                        >
                          MORE OPTIONS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-9 mt-4 ">
              <div className="w-100 d-flex mb-3  justify-content-between">
                
                {isShowBack && (
                  <button onClick={backtolist} className="btn btn-primary">
                    <i className="fa fa-arrow-left"></i> Back
                  </button>
                )}
              </div>

              {filteredData && filteredData.length > 0 ? (
                <div className={`row ${isShowBack?"":"mt-5 pt-1"}`}>
                  {filteredData.map((car) => (
                    <div
                      key={car.id}
                      className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
                    >
                      <div className="card  card_filter_card shadow-lg position-relative">
                        <div
                          className="image-container "
                          style={{ position: "relative" }}
                        >
                          <div className="row h-100">
                            <div className="col-8 h-100 pe-0">
                              <img
                                src={`https://trustedcars.in/api/public/uploads/cars/${car.image}`}
                                className="w-100"
                                loading="lazy"
                                alt="car"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderTopRightRadius: 0,
                                }}
                              />
                            </div>
                            <div className="col-4 h-100  pl-0">
                              <div className="row pl-0  grid_img">
                                {[...Array(3)].map((_, index) => {
                                  const image = car.gallery[index];
                                  if (image) {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: 60,
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src={`https://trustedcars.in/api/public/uploads/cars-gallery/${image.image}`}
                                          alt=""
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: 60,
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src="img/noimg.jpeg"
                                          alt="Placeholder"
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: "7px",
                              left: "7px",
                              zIndex: "1",
                              height: "26px",
                              width: "26px",
                            }}
                          >
                            <Heart
                              isActive={likeStatus[car.id]}
                              onClick={() => {
                                handleLike(car.id);
                              }}
                              animationTrigger="both"
                              inactiveColor="#00889c"
                              activeColor="#00889C"
                              animationDuration={0.2}
                            />
                          </div>
                        </div>
                        <Link
                          to={`/cardetails?data=${encodeURIComponent(
                            CryptoJS.AES.encrypt(
                              JSON.stringify(car),
                              "secret-key"
                            ).toString()
                          )}&min=${car.min_price}&max=${car.max_price}&isfav=${
                            car.isFav
                          }`}
                          className="card-body pb-1"
                        >
                          <div className="d-flex justify-content-between">
                            <h6
                              className="text-dark fw-semibold"
                              style={{ fontWeight: "14px" }}
                            >
                              ({car.year}) {car.make} {car.model}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6 className="text-dark fw-bold">Price Range:</h6>
                            <h6 className="fw-bold price">
                              £{formatEuroAmount(car.min_price)} - £
                              {formatEuroAmount(car.max_price)}
                              {/* £
                              {formatEuroAmount(
                                minPrice ? minPrice : fminPrice
                              )}{" "}
                              - £
                              {formatEuroAmount(
                                maxPrice ? maxPrice : fmaxPrice
                              )} */}
                            </h6>
                          </div>
                          <hr className="hr" />
                          <div className="d-flex flex-wrap car_info justify-content-between">
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/automatic.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car.transmission_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/speedometer.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {formatNumber(car.current_mileage)}
                            </h6>
                            <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/fuel.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car.fuel_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/engine.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {convertCcToLiters(car.engine_capacity)}L
                            </h6>
                          </div>

                          <hr className="hr" />

                          {car?.owner?.role === "dealer" ? (
                            <div className="mb-2 d-flex justify-content-between">
                              <div className="d-flex align-items-center ">
                                <div
                                  style={{
                                    height: "2.4rem",
                                    width: "2.4rem",
                                    border: "2px solid #ddd",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                  }}
                                  className="me-2 text-capitalize "
                                >
                                  <img
                                    src={`https://trustedcars.in/api/public/uploads/users/${car?.owner?.profile}`}
                                    alt=""
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "fill",
                                    }}
                                  />
                                </div>
                                <h6 className="my-0 text-primary fs-7 text-capitalize ">
                                  {car?.owner?.first_name}{" "}
                                  {car?.owner?.last_name}
                                </h6>
                              </div>
                              <div className="d-flex align-items-center">
                                <i className="fa text-primary me-2 fa-phone"></i>
                                <a
                                  className="fs-7 text-primary"
                                  href={"tel+" + car?.owner?.phone}
                                >
                                  {car?.owner?.phone}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="mb-2 pt-3 d-flex align-items-center justify-content-between">
                              <h6
                                className="text-primary   fw-medium d-flex align-items-center text-cap
                            alize"
                              >
                                Private Seller
                              </h6>
                              {/* {car?.owner?.phone && (
                                <div className="d-flex align-items-center">
                                  <i className="fa text-primary me-2 fa-phone"></i>
                                  <a
                                    className="fs-7 text-primary"
                                    href={"tel+" + car?.owner?.phone}
                                  >
                                    {car?.owner?.phone}
                                  </a>
                                </div>
                              )} */}
                            </div>
                          )}

                          <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                            <div className="d-flex align-items-center">
                              {car?.owner?.city && (
                                <>
                                  <img
                                    src="./img/pin.png"
                                    style={{ height: "20px", width: "20px" }}
                                    alt=""
                                  />
                                  <h6 className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                                    {car?.owner?.city} ({car?.owner?.country})
                                  </h6>
                                </>
                              )}
                            </div>
                            <div>
                              <p className="text-primary fs-7 pb-0 mb-0">
                                More <i className="fa fa-arrow-right"></i>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : loading ? (
                ""
              ) : (
                <h5 className="text-center text-capitalize">Car Not Found!</h5>
              )}
              {loading ? (
                <div className="text-center w-100 mt-5">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default FilterCarData;
