import React from 'react'
import { Link } from 'react-router-dom'
import About from '../About'

const SignIn = () => {
  return (
    <>
         <section className="account_login  pt-5 pb-4 w-100">
           <div className="container  ">
            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                <h2 className="text-center fw-bold"> Sign In Here</h2>
                <div className="card mt-4 border-0">
                    <div className="card-body py-4 px-4">
                        <h5 className=" mb-3">Your email address- <span className='fw-bold'>john123@gmail.com</span> isn't registered in our system, please sign in below :</h5>
                        <form action="">
                        <div className="mb-4">
                            <input type="text" placeholder="Username" className="w-100  position-relati🇻e " />
                            
                        </div>
                        <div className="mb-3">
                            <input type="password" placeholder="Password" className="w-100" />
                        </div>
                        <Link to='/forgetpassword'  className="blue">Forgotten Password ?</Link>
                       <div> <Link  className="btn btn-primary w-100 py-3 mt-3 fw-bold">LOGIN</Link></div>
                       
                        <div className="mt-3"> <Link to='/register'  className="btn btn-outline-primary w-100 py-3 fw-bold">SIGN UP</Link></div>
                      </form>
                    </div>
                </div>
            </div>
          </div>
          </section>

        <About/>
    </>
  )
}

export default SignIn