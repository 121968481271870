import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
   <>
    <footer className=" mb-0 " style={{color:"black"}}>
      
       <div className="container py-3 py-md-4">
        <div className="text-center">
            <div className="d-flex justify-content-center align-items-center brand">
                <img src="img/logo.png" alt="" />
                <h2 className='blue  ms-2 mb-0'>Carloca</h2>
            </div>
            <p className='m-0 sml'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui temporibus consectetur cupiditate soluta voluptatibus nulla officiis, et error voluptates, sed minima voluptate, illum obcaecati impedit est. </p>
            <ul className="d-flex justify-content-center align-items-center flex-wrap all_media_icons p-0 m-0 py-2">
              <li><Link><img src="img/foot-msg.png" alt="" /></Link></li>
              <li><Link><img src="img/foot-fb.png" alt="" /></Link></li>
              <li><Link><img src="img/foot-insta.png" alt="" /></Link></li>
               </ul>
                <p className="text-center m-0 sml">Copyright &copy; 2023 - Carloca.com</p>
                <div className="d-flex justify-content-center align-items-center  mt-2">
                    <ul className='p-0 d-flex justify-content-center flex-wrap policies m-0 sml'>
                        <li className='m-2'><Link className='text-decoration-none'>Imprint/Privacy </Link>&nbsp;&nbsp;&nbsp;|</li>
                        <li className='m-2'><Link className='text-decoration-none'>Terms & Conditions </Link>&nbsp;&nbsp;&nbsp;|</li>
                        <li className='m-2'><Link className='text-decoration-none'>Feedback</Link></li>
                    </ul>
                </div>
        </div>
       </div>
     </footer>
   </>
  )
}

export default Footer