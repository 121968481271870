import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import ReactApexChart from "react-apexcharts";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const MyCredits = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [myCeredit, setMyCredit] = useState(0);

  const [state, setState] = useState({
    series: [
      {
        name: "Credits",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Spent ",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      toast.warning("Please login before access this.", optionToast);
      setTimeout(() => {
        window.location.href = `#/login`;
      }, 1500);
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/get/credits",
        {
          user_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "ddssd");
      setMyCredit(response?.data?.total_credit);

      //   saveDataToSessionStorage('userData', response?.data?.data);
      // alert(response?.data?.message)
      // setBidValue('')
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const fetchCredits = async () => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/get/user/credits",
        {
          user_id: storedUserPreferences?.id,
        }
      );

      const myCredits=response?.data?.data;
      const categories = Object.keys(myCredits);
      const dataSeries = Object.values(myCredits);
      setState({
        series: [
          {
            name: "Credits",
            data: dataSeries,
          },
        ],
        options: {
          chart: {
            height: 250,
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: "Spent ",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: categories,
          },
        },
      })
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchCredits();
  }, []);

  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
                <SmallSearch/> */}
        <div className="my_credits  container all_listings sml-car mt-5 py-5">
          <h3 className="py-3">My Credits</h3>
          <div className="row">
            <div className="col-md-5 col-sm-12 mb-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                  <h5 style={{ color: "grey" }}>Current Balance</h5>
                  <div className="d-flex align-items-center mt-3">
                    <h2 style={{ fontSize: "26px" }}> {myCeredit} </h2>{" "}
                    <span>Credits</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 mb-4">
              <div className="card">
                <div className="card-body ">
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={250}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Link to="/buyplan">
              <button className="btn btn-primary px-3 py-2">Get Credits</button>
            </Link>
          </div>

          <div className="d-flex flex-column align-items-center mt-5 justify-content-center">
            <h4 className="text-center">Want to unlock more cars?</h4>
            <section className="about container d-flex flex-column  py-3">
              <div className="row  justify-content-center ">
                <div className="col-lg-7 col-sm-6 p-1 p-md-3">
                  <div className="">
                    <p className="m-0">
                      Our premier online platform is for car enthusiasts and
                      sellers. We understand the importance of trust, great
                      offers and finding cars with certain specifications.
                    </p>
                    <p className="m-0">
                      That's why our platform is designed to offer a
                      straightforward, secure, and efficient experience like no
                      other
                    </p>
                  </div>
                  <div className=" mt-3">
                    <h5 className="fw-bold">
                      Tired of <span className="blue">cold calls</span> and{" "}
                      <span className="blue">time wasters?</span>
                    </h5>
                    <p className="m-0">
                      Carloca is the genuine platform addresses just that by
                      using a credit system which the lister has full control
                      over.{" "}
                    </p>
                    <p className="m-0">
                      Whether you're looking to invest in a new car or position
                      yours for sale, Carloca stands as your reliable partner.
                    </p>
                  </div>
                </div>
                <div className="offset-lg-1 col-lg-4 col-sm-6 p-1 p-md-3">
                  <img
                    src="img/how_use1.jpg.png"
                    className="w-100 h-100 mb-3 rounded-2"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default MyCredits;
