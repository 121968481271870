import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Bounce, ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import "./buyplan.css";
import checkImg from "../check-circle.svg";
import axios from "axios";
import { Link } from "react-router-dom";
const stripePromise = loadStripe("pk_test_4QBduHSstG91diuSlCElknos");

const BuyPlan = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");

  useEffect(() => {
    if (storedUserPreferences === null) {
      toast.warning("Please login before access this.", optionToast);
      setTimeout(() => {
        window.location.href = `#/login`;
      }, 1500);
    }
  }, []);
  const amountPay = (selectedOption) => {
    switch (selectedOption) {
      case "1":
        setPayAmount(3);
        break;
      case "10":
        setPayAmount(20);
        break;
      case "100":
        setPayAmount(50);
        break;
      case "150":
        setPayAmount(100);
        break;
      default:
        setPayAmount(0);
        break;
    }
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setClientSecret("");
    setLoading(false);

    amountPay(event.target.value);
  };
  // Create PaymentIntent as soon as the page loads
  const getCredits = async () => {
    setLoading(true);

    await fetch(
      "https://trustedcars.in/api/public/api/create-payment-intent2",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: payAmount }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          setClientSecret(data.data.clientSecret);
        } else {
          toast.error(data.message, optionToast);
        }
      });
    setLoading(false);
  };
  const handleTerms=()=>{

    if(isChecked){
  
      getCredits();
    }else{
      toast.error('Please accept our terms and conditions.',optionToast);
    }
  }

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Navbar />

      <div className="col-lg-6 col-md-9 col-sm-12 mx-auto px-3 py-5 mt-5 ">
        <div className="card buy_plan border-0">
          <div className="card-body py-4 px-4">
            <h4 className="mb-3">Unlock with Credits</h4>
            <ol className="bullet_points ">
              <li>
                <img src={checkImg} alt="" />
                <h5 className="fw-semibold fs-6">No refunds for credits</h5>
              </li>
              <li>
                <img src={checkImg} alt="" />
                <h5 className="fw-semibold fs-6">1 year expiry on credits</h5>
              </li>
            </ol>
            <div>
              <div className="table-responsive mb-4">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault5"
                            id="radio1"
                            value="1"
                            checked={selectedOption === "1"}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </td>
                      <td>1 Credit</td>
                      <td>£3</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault5"
                            id="radio2"
                            value="10"
                            checked={selectedOption === "10"}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </td>
                      <td>10 Credit</td>
                      <td>£20</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault5"
                            id="radio3"
                            value="100"
                            checked={selectedOption === "100"}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </td>
                      <td>100 Credit</td>
                      <td>£50</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault5"
                            id="radio4"
                            value="150"
                            checked={selectedOption === "150"}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </td>
                      <td>150 Credit</td>
                      <td>£100</td>
                    </tr>
                   
                  </tbody>
                </table>
                      <div className="input-group mx-2 mt-4" >
                         
                        <input
                          id="tandc"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="form-check-input me-3"
                          style={{ height: 15, width: 15 }}
                          type="checkbox"
                        />
                        <label>Accept our <Link to="/term-and-condition" className="blue p-0 m-0" >Terms & Conditions</Link></label>
                      </div>
                     
                     
                    
              </div>
            </div>
            {!clientSecret && (
              <div className="text-center ">
                <button
                  type="button"
                  onClick={handleTerms}
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Get Credits"}
                </button>
              </div>
            )}

            <div className="row justify-content-center" id="formtag">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BuyPlan;
