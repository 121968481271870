import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../Footer";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
export default function EditCar() {
  const navigate = useNavigate();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [currentMileage, setCurrentMileage] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [carData, setCarData] = useState([]);
  const [car, setCarOldData] = useState([]);
  const [isCarData, setCarDataAv] = useState(false);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [model_varient, setModelVarient] = useState("");
  const [year, setYear] = useState("");
  const [fuel_type, setFuelType] = useState("");
  const [color, setColor] = useState("");
  const [body_type, setBodyType] = useState("");
  const [transmission_type, setTransmissionType] = useState("");
  const [seating_capacity, setSeatingCapacity] = useState("");
  const [checking, setChecking] = useState(false);
  const [isCarAccept, setIsCarAccept] = useState(true);
  const [preview, setPreview] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isAbleSubmit, setIsAbleSubmit] = useState(false);
  const [errors, setErrors] = useState({ mileage: "", email: "", phone: "" });
  const location = useLocation();

  //   console.log(data);
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");

  useEffect(() => {
    // const storedUserPreferences = getDataFromSessionStorage('userData');
    if (storedUserPreferences === null) {
      toast.error("Please login first to upload", optionToast);
      navigate("/login");
    }
    const { data } = location.state;
    setCarOldData(location.state.data);
    setRegistrationNumber(data?.registration_number);
    setCurrentMileage(data?.current_mileage);
    setMinPrice(data?.min_price);
    setMaxPrice(data?.max_price);
    setEmail(data?.email);
    setTel(data?.phone);
    setSpecifications(data?.specifications);
    setBodyType(data?.body_type);
    setColor(data?.color);
    setFuelType(data?.fuel_type);
    setYear(data?.year);
    setMake(data?.make);
    setModel(data?.model);
    setModelVarient(data?.model_varient);
    setSeatingCapacity(data?.seating_capacity);
    setTransmissionType(data?.transmission_type);
  }, []);

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages([...images, ...selectedImages]);
  };
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const updateCarInList = () => {
    if (registrationNumber === car?.registration_number) {
      const formData = {
        car_id: car?.id,
        owner_id: storedUserPreferences?.id,
        registration_number: registrationNumber,
        min_price: minPrice,
        max_price: maxPrice,
        make: make,
        model: model,
        model_varient: model_varient,
        year: year,
        fuel_type: fuel_type,
        body_type: body_type,
        transmission_type: transmission_type,
        color: color,
        seating_capacity: seating_capacity,
        specifications: specifications,
        phone: tel,
        email: email,
        latitude: 40.7128,
        longitude: -74.006,
        images: images,
        previewImg:previewImg,
        current_mileage: currentMileage,
      };

      axios
        .post("https://trustedcars.in/api/public/api/car/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res?.data);
          setLoading(false);

          if (res?.data?.status) {
            setRegistrationNumber("");
            setCurrentMileage("");
            setMinPrice("");
            setMaxPrice("");
            setSpecifications("");
            setImages([]);
            setTel("");
            setEmail("");
            setModelVarient("");
            navigate("/thankyou", { state: { data: res?.data } });
            setCarDataAv(false);
          } else {
            toast.error(res?.data?.message, optionToast);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      if (!isCarAccept) {
        if (isCarData) {
          const formData = {
            car_id: car?.id,
            owner_id: storedUserPreferences?.id,
            registration_number: registrationNumber,
            min_price: minPrice,
            max_price: maxPrice,
            make: make,
            model: model,
            model_varient: model_varient,
            year: year,
            fuel_type: fuel_type,
            body_type: body_type,
            transmission_type: transmission_type,
            color: color,
            seating_capacity: seating_capacity,
            specifications: specifications,
            phone: tel,
            email: email,
            latitude: 40.7128,
            longitude: -74.006,
            images: images,
            current_mileage: currentMileage,
          };

          axios
            .post(
              "https://trustedcars.in/api/public/api/car/update",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              // console.log(res?.data);
              setLoading(false);

              if (res?.data?.status) {
                setRegistrationNumber("");
                setCurrentMileage("");
                setMinPrice("");
                setMaxPrice("");
                setSpecifications("");
                setImages([]);
                setTel("");
                setEmail("");
                navigate("/thankyou", { state: { data: res?.data } });
                setCarDataAv(false);
              } else {
                toast.error(res?.data?.message, optionToast);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        } else {
          setLoading(false);
          setIsAbleSubmit(true);
          toast.error(
            "Please verify your car registration number!",
            optionToast
          );
        }
      } else {
        toast.error('Please accept this car is your?');
      }
    }
  };
  const getCarData = () => {
    setChecking(true);
    const apiKey = "102052c6-d059-4e04-b51d-0617eec8bd7c";
    axios
      .get(
        "https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=" +
          apiKey +
          "&user_tag=&key_VRM=" +
          registrationNumber +
          ""
      )
      .then((res) => {
        // console.log(res?.data);

        setChecking(false);

        if (res?.data?.Response?.StatusCode === "Success") {
          setCarData(res?.data?.Response?.DataItems);
          const carDetail = res?.data?.Response?.DataItems;
          setMake(carDetail?.VehicleRegistration?.Make);
          setModel(carDetail?.VehicleRegistration?.Model);
          setModelVarient(carDetail?.SmmtDetails?.ModelVariant);
          setBodyType(carDetail?.SmmtDetails?.BodyStyle);
          setFuelType(carDetail?.VehicleRegistration?.FuelType);
          setColor(carDetail?.VehicleRegistration?.Colour);
          setTransmissionType(carDetail?.VehicleRegistration?.TransmissionType);
          setYear(carDetail?.VehicleRegistration?.YearOfManufacture);
          setSeatingCapacity(carDetail?.VehicleRegistration?.SeatingCapacity);
          setIsAbleSubmit(true);
          setCarDataAv(true);
          setIsCarAccept(true);
        } else {
          setIsAbleSubmit(true);
          setIsCarAccept(true);
          setCarDataAv(false);
          setCarData([]);
          setMake("");
          setModel("");
          setModelVarient("");
          setBodyType("");
          setFuelType("");
          setColor("");
          setTransmissionType("");
          setYear("");
          setSeatingCapacity("");
          toast.error("Registration number is wrong!", optionToast);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isCarRegistrationExist = () => {
    setChecking(true);
    var car_id = car["id"];
    // alert(car_id);
    const formData = {
      registration_number: registrationNumber,
      id: car_id,
    };
    axios
      .post("https://trustedcars.in/api/public/api/car/data", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setChecking(false);
        if (res?.data?.code === 101) {
          getCarData();
        } else {
          toast.error(res?.data?.message, optionToast);
          setChecking(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation logic
    if (!tel) {
      toast.error("Please fill in the phone number.", optionToast);
      return;
    }
    if (!email) {
      toast.error("Please fill in the email address.", optionToast);
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.", optionToast);
      return;
    }
    if (!registrationNumber) {
      toast.error("Please fill in the registration number.", optionToast);

      return;
    }
    if (!currentMileage) {
      toast.error("Please fill in the current mileage.", optionToast);
      return;
    }
    if (!minPrice) {
      toast.error("Please fill in the minimum price.", optionToast);

      return; // Stop further execution if minimum price is missing
    }
    if (!maxPrice) {
      toast.error("Please fill in the maximum price.", optionToast);
      return;
    }
    if (!specifications) {
      toast.error("Please fill in the specifications.", optionToast);
      return;
    }

    setLoading(true);

    // Call addCarToList only if all fields are valid
    updateCarInList();
  };

  const generatePriceOptions = (min, max, step) => {
    const options = [];
    for (let value = min; value <= max; value += step) {
      options.push(value);
    }
    return options;
  };
  const priceOptions = generatePriceOptions(0, 100000, 5000);
  const handlePreviewImgChange = async (e) => {
    const file = e.target.files[0];
    setPreviewImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setUploading(false);
    } else {
      setUploading(false);
    }
  };
  const handleDeleteImage = async (imageId) => {
    const formData = {
      image_id: imageId,
    };
    try {
      await axios
        .post(
          `https://trustedcars.in/api/public/api/car/delete/gallery`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response?.data?.status) {
            setCarOldData((prevCar) => ({
              ...prevCar,
              gallery: prevCar.gallery.filter(
                (carImage) => carImage.id !== imageId
              ),
            }));
            toast.success("Image deleted successfully", optionToast);
          } else {
            toast.error(response?.data?.message, optionToast);
          }
        });
    } catch (error) {
      console.error("Error deleting the image", error);
      alert("Failed to delete image");
    }
  };
  const validateMileage = (value) => {
    if (/^\d*$/.test(value)) {
      setErrors((prev) => ({ ...prev, mileage: "" }));
      setCurrentMileage(value);
    } else {
      setErrors((prev) => ({
        ...prev,
        mileage: "Please enter a valid current mileage (Like 20, 39, etc).",
      }));
    }
  };

  const validateEmail = (value) => {
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };

  const validatePhone = (value) => {
    setTel(value);
    const phoneRegex = /^\+?\d{0,15}$/;
    if (value && !phoneRegex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        phone:
          "Please enter a valid phone number (10 to 15 digits, with optional +).",
      }));
    } else if (value.length >= 10 && value.length <= 15) {
      setErrors((prev) => ({ ...prev, phone: "" }));
    } else if (value.length > 0 && (value.length < 10 || value.length > 15)) {
      setErrors((prev) => ({
        ...prev,
        phone: "Phone number must be between 10 and 15 digits.",
      }));
    }
  };
  return (
    <>
      <Navbar />
      <div className="new_page w-100 container-fluid">
        <div className="list_details add_car_container row  shadow-0  p-sm-4 p-0 mb-5 d-flex flex-column justify-content-center align-items-center">
          <form
            action=""
            className="col-md-8 d-flex flex-column justify-content-center mt-5"
          >
            <div className="row border-0 pb-0">
              <div className="col-md-12">
                <div className="ps-2">
                  <h4 className="fs-4 fw-semibold mt-2 poppins-semibold">
                    Edit Your Car
                  </h4>
                  <p className="poppins-medium mt-3 fs-sm">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="search-main p-0  mt-md-3 mt-3">
              <div
                className="card glassmorphism-1 "
                style={{ boxShadow: "none !important" }}
              >
                <div className="card-body   w-100 d-flex align-items-center justify-content-around">
                  <div className="input-group py-1  ">
                    <input
                      type="text"
                      placeholder="Enter Reg Here"
                      name="registration"
                      id="registration"
                      className="form-control form-control-lg"
                      value={registrationNumber}
                      onChange={(e) => {
                        setRegistrationNumber(e.target.value);
                        if (e.target.value === car?.registration_number) {
                          setIsAbleSubmit(false);
                        } else {
                          setIsAbleSubmit(true);
                        }
                      }}
                    />
                    <button
                      type="button"
                      onClick={isCarRegistrationExist}
                      disabled={checking}
                      className="decided-btn input-group-btn"
                    >
                      {checking ? "Checking..." : "Check"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isCarData && (
              <div className="col-md-12 ">
                <div className="row border-0 justify-content-center">
                  <div className="col-sm-12 col-md-8">
                    <div className="ps-2">
                      <h4 className="fs-4 fw-semibold mt-5 mb-3 poppins-semibold">
                        Is This Your Car?
                      </h4>
                    </div>
                    <div className="card glassmorphism-1 ">
                      <div className="card-body p-md-5 p-3">
                        <div className="card border-0 rounded-3 shadow-lg">
                          <div className="card-body card_detail_list">
                            <ul>
                              <li>
                                <span className="list-heading">Make:</span>
                                <span className="list-text">{make}</span>
                              </li>
                              <li>
                                <span className="list-heading">Model:</span>
                                <span className="list-text">{model}</span>
                              </li>
                              <li>
                                <span className="list-heading">Year:</span>
                                <span className="list-text">{year}</span>
                              </li>
                              <li>
                                <span className="list-heading">Colour:</span>
                                <span className="list-text">{color}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {isCarAccept && (
                          <div className="d-flex mt-3 justify-content-around">
                          <button
                            type="button"
                            className="decided-btn"
                            onClick={() => {
                              setIsCarAccept(false);
                              toast.success(
                                "Thanks for confirmation.",
                                optionToast
                              );
                              setIsAbleSubmit(false);
                              const infoElement =
                                document.querySelector("#info_about");
                              if (infoElement) {
                                infoElement.scrollIntoView({
                                  behavior: "smooth",
                                });
                              }
                            }}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="decided-btn"
                            onClick={() => {
                              setCarDataAv(false);
                            }}
                          >
                            No
                          </button>
                        </div>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <h3 className="text-start mt-3">Contact Details</h3>

            <p>
              Tell us about the specifications of your car, have you made any
              moderations on your car which makes it stand out from others?
            </p>

            <div className="row" id="info_about">
              <div className="col-md-6 mb-3">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Tel"
                    value={tel}
                    onChange={(e) => validatePhone(e.target.value)}
                    style={{
                      borderColor: errors.phone ? "red" : "",
                    }}
                  />
                  <i className="fa-solid fa-phone"></i>
                </div>
                {errors.phone && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.phone}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <div className="position-relative">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => validateEmail(e.target.value)}
                    style={{
                      borderColor: errors.email ? "red" : "",
                    }}
                  />
                  <i className="fa-solid fa-envelope"></i>
                </div>
                {errors.email && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <h3 className="py-3">About The Car</h3>
            <div className="row">
              <div className="col-md-12 mb-3">
                <input
                  type="text"
                  placeholder="Current Mileage"
                  value={currentMileage}
                  onChange={(e) => validateMileage(e.target.value)}
                  style={{
                    borderColor: errors.mileage ? "red" : "",
                  }}
                />
                {errors.mileage && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.mileage}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <select
                  className="form-select"
                  aria-label="Min Price"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                >
                  <option>Min Price</option>
                  {priceOptions.map((price) => (
                    <option key={price} value={price}>
                      £{price.toLocaleString()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <select
                  className="form-select"
                  aria-label="Max Price"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                >
                  <option>Max Price</option>
                  {priceOptions.map((price) => (
                    <option key={price} value={price}>
                      £{price.toLocaleString()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 mb-3">
                <textarea
                  placeholder="Write Specifications"
                  value={specifications}
                  onChange={(e) => setSpecifications(e.target.value)}
                  rows="6"
                ></textarea>
              </div>
              <h5>Preview Image</h5>
              <div className="col-sm-4 col-6 ">
                <img
                  src={
                    preview
                      ? preview
                      : `https://trustedcars.in/api/public/uploads/cars/${car?.image}`
                  }
                  alt=""
                  style={{ width: "100%" }}
                  className="rounded-2"
                />
                <label
                  className="btn my-2 btn-primary btn-sm "
                  htmlFor="prevfile"
                  disabled={uploading}
                >
                  <i className="fa fa-edit"></i>
                </label>
                {uploading && <p>Uploading...</p>}
                <input
                  id="prevfile"
                  className="d-none"
                  type="file"
                  name="prevfile"
                  accept="image/*"
                  onChange={handlePreviewImgChange}
                />
              </div>
              <div className="col-12 mb-3">
                <div className="border text-center bg-white py-5">
                  <input
                    id="upload"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="upload">
                    <img
                      style={{ width: "80px", cursor: "pointer" }}
                      src="img/gallery_icon.png"
                      alt=""
                    />
                  </label>
                </div>
              </div>
              <div className="col-12 mb-3">
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeImage(index)}
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>

              <h5 className="my-3">Gallery Images</h5>
              {car?.gallery != null && car?.gallery !== undefined
                ? car?.gallery.map((carimage) => (
                    <div className="col-sm-4 col-6 " key={carimage.id}>
                      <img
                        src={`https://trustedcars.in/api/public/uploads/cars-gallery/${carimage?.image}`}
                        alt=""
                        style={{ width: "100%" }}
                        className="rounded-2"
                      />
                      <button
                        type="button"
                        className="btn my-2 btn-danger btn-sm "
                        onClick={() => handleDeleteImage(carimage?.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  ))
                : ""}

              <div className="d-flex justify-content-center flex-column align-items-center">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary text-center"
                  disabled={isAbleSubmit}
                >
                  {loading ? "Uploading..." : "Save Changes"}
                </button>
                <small className="text-primary mt-2">
                  {isAbleSubmit ? "Please verify your reg no." : ""}
                </small>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}
