import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import SmallSearch from '../components/SmallSearch'
import Footer from '../Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Navigate } from 'react-router-dom';

const DeleteAccount = () => {
    const [redirect, setRedirect] = useState(false);

    const getDataFromSessionStorage = (key) => {
        try {
            const serializedData = sessionStorage.getItem(key);
            return serializedData ? JSON.parse(serializedData) : null;
        } catch (error) {
            console.error('Error getting data from sessionStorage:', error);
            return null;
        }
    };

    // Example usage:
    const storedUserPreferences = getDataFromSessionStorage('userData');
    const handleDeleteConfirmation = () => {
        // Show confirmation alert
        const isConfirmed = window.confirm("Are you sure you want to delete the account?");

        // If user confirms deletion, perform deletion action
        if (isConfirmed) {
            // Call your deletion function here
            deleteAccount();
        }
    };

    const deleteAccount = async () => {
        // Perform deletion logic here
        try {
            const response = await axios.post(
                "https://trustedcars.in/api/public/api/user/delete",
                {
                    user_id: storedUserPreferences?.id,
                }
            );
            // setData(response?.data)
            console.log(response?.data, "ssss");
            if (response?.data?.status) {
                // const userConfirmed = window.confirm(response?.data?.message);
                // // setData(res?.data)
                // // Check if the user clicked "OK"
                // if (userConfirmed) {

                //     // fetchData(1); // Fetch data here, e.g., call fetchData(1)
                // } else {
                //     alert(response?.data?.message);
                // }
                sessionStorage.removeItem('userData');
                setRedirect(true);

            }

        } catch (error) {
            console.log("An error occurred while fetching data.", error);
        }

    };
    return (
        <>
            <Navbar />
            <div className="account_details">
                {/* <SmallSearch/> */}
                <div className="delete_account  col-lg-7 col-md-9 col-sm-12 px-3 mt-5 mx-auto py-5">
                    <h2 className="fw-bold">Delete Account</h2>
                    <div className="card mt-3 border-0">
                        <div className="card-body pb-1">
                            <h4 className="text-center blue mb-4">
                                We don't want to say <span className="fw-bold">Goodbye</span>
                            </h4>
                            <ul className="p-0">
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                                <li className="d-flex  mb-3 blue"><i className="fa-solid fa-check me-2 pt-1"></i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, explicabo.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-between">
                        <Link to='/profileSetting' className="btn btn-sm btn-outline-primary rounded-1">Cancel</Link>
                        <button
                            className="btn btn-sm btn-danger rounded-1"
                            onClick={handleDeleteConfirmation}
                        >
                            Delete Account
                        </button>
                    </div>
                </div>
                <Footer />
                {redirect && <Navigate to="/" />}

            </div>
        </>
    )
}

export default DeleteAccount