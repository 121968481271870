import React from 'react'

const ConfirmReg = () => {
  return (
    <>
        <section className="conf_reg d-flex flex-column align-items-center justify-content-center mt-5">
        <div className="container ">
        <h4 className="text-center">
            Please check your email inbox and verify email address through our confirmation email.
        </h4>
        </div>
        </section>
    </>
  )
}

export default ConfirmReg