import React from 'react'
import Navbar from '../components/Navbar'

const PurchaseCredit = () => {
  return (
    <>
        <Navbar/>
        <div className="col-lg-6 col-md-9 col-sm-11 px-3 mx-auto mt-5 pt-5 purchase_credit">
            <div className="card border-0">
                <div className="card-body py-4">
                    <h4 className="text-center mb-3 blue">
                        Thank You for purchasing your credits <br />you now have access to:
                        </h4>
                        <div className=" py-2  mb-3 d-flex justify-content-center">
                            <h6 className='border py-2 px-3 rounded-2'>Total no. of Credits <span className="fw-bold">50</span></h6>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-primary rounded-pill">  Back to car 
                             </button>
                        </div>
                   
                </div>
            </div>
        </div>
    </>
  )
}

export default PurchaseCredit