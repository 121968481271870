import React from 'react'

const ChangePass = () => {
  return (
    <section className="change_pass mt-4 py-5">
    <div className="container">
        <h4 className='text-center'>
                Please check your email- <span className='fw-bold'>john123@gmail.com</span> to change your password.
        </h4>
    </div>
    </section>
  )
}

export default ChangePass