import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import About from "../About";
import HowToUse from "./HowToUse";
import axios from "axios";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import { jwtDecode } from "jwt-decode";

const HomePage = () => {
  const navigator = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [distance, setDistance] = useState("");

  const [name, setName] = useState("");
  const [model, setModel] = useState("");
  const [model_varient, setModelVariant] = useState("");
  const [fuelType, setFuelType] = useState([]);
  const [gearbox, setGearbox] = useState([]);
  const [colors, setColors] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [modelVarinets, setModelVariants] = useState([]);
  const [bodTypes, setBodyTypes] = useState([]);
  const [makeData, setMakeData] = useState([]);
  const [grearBoxs, setGearBoxs] = useState([]);
  const [allColors, setAllColors] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);

  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  // const minMileage = "";
  const [minMileage, setminMileage] = useState("");
  const [maxMileage, setmaxMileage] = useState("");
  const [minPrice, setminPrice] = useState(0);
  const [maxPrice, setmaxPrice] = useState(100000);
  const [engineSize, setEngineSize] = useState("");
  const [body_type, setBodyType] = useState("");

  const [profile, setProfile] = useState(null);
 
  const startYear = 2014;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  const mileOptions = [];
  for (let mile = 5; mile <= 12; mile++) {
    mileOptions.push(mile);
  }
  const mmileOptions = [];
  for (let mile = 12; mile <= 20; mile++) {
    mmileOptions.push(mile);
  }
  const REACT_APP_GG_APP_ID =
    "1053333615945-mnk7qqgqtr1gmmd2gj0j03p9itlp198f.apps.googleusercontent.com";

  function getSelectedCheckboxValuesAsQueryString(name) {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}"]:checked`
    );
    let values = [];
    checkboxes.forEach((checkbox) => {
      return values.push(checkbox.value);
    });
    return values;
  }
  const saveDataToSessionStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      sessionStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to sessionStorage:", error);
    }
  };
  const loginWithGoogle = async (email, name, profile) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/login-google/user",
        {
          email: email,
          name: name,
          profile: profile,
        }
      );
      //  console.log(response?.data);
      if (response?.data?.status) {
        saveDataToSessionStorage("userData", response?.data?.data);
        showAlert(
          response?.data?.message,
          response?.data?.data?.id,
          response?.data?.status
        );
      } else {
        toast.error(response?.data?.message, optionToast);
        Navigate("/signin");
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const showAlert = (data, id, show) => {
    if (show) {
      toast.success(data, optionToast);
      setTimeout(() => {
        navigator("/profilesetting");
      }, 1500);
    } else {
      toast.error(data, optionToast);
    }
  };
  // const handleEditProfile = (id) => {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="custom-ui">
  //           <h1>Are you sure?</h1>
  //           <p>You want to edit your profile?</p>
  //           <button
  //             className="btn btn-danger me-2 rounded-0 btn-sm"
  //             onClick={() => {
  //               window.location.href = `#/`;
  //               onClose();
  //             }}
  //           >
  //             No
  //           </button>
  //           <button
  //             className="btn btn-primary rounded-0 btn-sm"
  //             onClick={() => {
  //               window.location.href = `#/profilesetting`;
  //               onClose();
  //             }}
  //           >
  //             Yes
  //           </button>
  //         </div>
  //       );
  //     },
  //   });
  // };
  function updateURL() {
    setGearbox(getSelectedCheckboxValuesAsQueryString("gearbox[]"));
    setFuelType(getSelectedCheckboxValuesAsQueryString("fueltype[]"));
    setColors(getSelectedCheckboxValuesAsQueryString("colors[]"));
  }

  useEffect(() => {
    getMake();
    getGearbox();
    getColors();
    getFuelTypes();
  }, []);
  const resetFilter=()=>{
    
    setName("");
    setModel("");
    setModelVariant('');
    setFuelType([]);
    setGearbox([]);
    setColors([]);
    setBodyTypes([]);
    setmaxMileage("");
    setminMileage("");
    setminPrice("");
    setmaxPrice("");
    setEngineSize("");
    setBodyType("");
    // setMakeData([]);
    setDistance(""); 
    setMaxYear("");
    setMinYear("");
    
  }
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromSessionStorage("userData");
  const getModal = async (companyName) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/model",
        { make: companyName }
      );
      if (response?.data?.status) {
        setModelData(response?.data?.data);
      }
      setModel("");
      setModelVariant("");
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getModalVarient = async (model) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/model-variant",
        { model: model }
      );
      if (response?.data?.status) {
        setModelVariants(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getBodyType = async (model) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/cars/body-type",
        { model: model }
      );
      if (response?.data?.status) {
        // console.log(response?.data?.data)
        setBodyTypes(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const getMake = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/make"
      );
      if (response?.data?.status) {
        // console.log(response?.data?.data, "make")
        setMakeData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getGearbox = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/gearbox"
      );
      if (response?.data?.status) {
        setGearBoxs(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getFuelTypes = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/fuel"
      );
      if (response?.data?.status) {
        setFuelTypes(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const getColors = async () => {
    try {
      const response = await axios.get(
        "https://trustedcars.in/api/public/api/cars/colors"
      );
      if (response?.data?.status) {
        setAllColors(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const [showDiv, setShowDiv] = useState(false);

  // Function to toggle the visibility of the div
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  const handleGoogleLoginSuccess = async (response) => {
    const { credential } = response;
    const decodedToken = jwtDecode(credential);
    if (decodedToken !== null) {
      const email = decodedToken?.email;
      const name = decodedToken?.name;
      const profilePicture = decodedToken?.picture;
      loginWithGoogle(email, name, profilePicture);
      setProfile(decodedToken);
    } else {
      toast.error("Unable to get profile", optionToast);
    }
  };
  const generateOptions = () => {
    const options = [];
    for (let i = 1.1; i <= 10; i += 0.1) {
      options.push(i.toFixed(1)); 
    }
    return options;
  };
  return (
    <>
      <div className="new_homepage">
        <div className="main_banner1 w-100 pb-0 pb-md-4 ">
          <div className="container">
            <div className="row my-md-2 mb-md-3 mt-5">
              <div className="col-md-7">
                <div className="card border-0 bg-white my-4">
                  <div className="card-body py-2 pt-md-3 p-0 p-xl-4 p-md-3 p-sm-2 p-2 ">
                    <div className="search_area ">
                      <div className="d-flex justify-content-between py-2">
                        <button
                          type="button"
                          id="btn1"
                          className="btn btn-primary w-100 mb-2 mx-2"
                        >
                          Find My Car
                        </button>

                        <Link
                          to="/listmycar"
                          id="btn2"
                          className="btn btn-light w-100 mb-2 mx-2"
                        >
                          List My Car
                        </Link>
                      </div>
                      <div
                        id="filters"
                        className="filters mt-2 search_area container "
                      >
                        <h6 className="fw-bold d-block mb-3">
                          Discover Your Ideal Car
                        </h6>
                        <div className="card pt-2 ">
                          <div className="card-body p-0 p-lg-3 py-2 ">
                            <form className="d-flex w-100 flex-column align-items-center justify-content-center">
                              <div className="row w-100">
                                {/* make */}
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={name}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      getModal(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Make
                                    </option>
                                    {makeData.map((item, index) => (
                                      <option key={index} value={item?.make}>
                                        {item?.make}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={model}
                                    onChange={(e) => {
                                      setModel(e.target.value);
                                      getModalVarient(e.target.value);
                                      getBodyType(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Model
                                    </option>
                                    {modelData.map((item, index) => (
                                      <option key={index} value={item?.model}>
                                        {item?.model}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={model_varient}
                                    onChange={(e) =>
                                      setModelVariant(e.target.value)
                                    }
                                  >
                                    <option value="">Model Variant</option>
                                    {modelVarinets.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item?.model_varient}
                                      >
                                        {item?.model_varient}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                {/* <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={minMileage}
                                    onChange={(e) =>
                                      setminMileage(e.target.value)
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option>Min Mileage</option>
                                    <option value="5">At Least 5 Miles</option>
                                    <option value="6">At Least 6 Miles</option>
                                    <option value="7">At Least 7 Miles</option>
                                    <option value="8">At Least 8 Miles</option>
                                    <option value="9">At Least 9 Miles</option>
                                    <option value="10">
                                      At Least 10 Miles
                                    </option>
                                    <option value="11">
                                      At Least1 11 Miles
                                    </option>
                                    <option value="12">
                                      At Least 12 Miles
                                    </option>
                                  </select>
                                </div> */}
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={maxMileage}
                                    onChange={(e) =>
                                      setmaxMileage(e.target.value)
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option>Max Mileage</option>
                                    {mmileOptions.map((mile) => (
                                      <option key={mile} value={mile}>
                                        Upto {mile} Miles
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={minYear}
                                    onChange={(e) => setMinYear(e.target.value)}
                                    aria-label="Default select example"
                                  >
                                    <option>Min Year</option>
                                    {years.map((yearl) => (
                                      <option key={yearl} value={yearl}>
                                        {yearl}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="col-6 mb-2">
                                  <select
                                    className="form-select"
                                    value={maxYear}
                                    onChange={(e) => setMaxYear(e.target.value)}
                                    aria-label="Default select example"
                                  >
                                    <option>Max Year</option>
                                    {years.map((yearl) => (
                                      <option key={yearl} value={yearl}>
                                        {yearl}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={minPrice}
                                    onChange={(e) =>
                                      setminPrice(e.target.value)
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option>Min Price</option>
                                    <option value="0">£0</option>
                                    <option value="5000">£5,000</option>
                                    <option value="10000">£10,000</option>
                                    <option value="20000">£20,000</option>
                                    <option value="30000">£30,000</option>
                                    <option value="40000">£40,000</option>
                                    <option value="50000">£50,000</option>
                                    <option value="60000">£60,000</option>
                                    <option value="70000">£70,000</option>
                                    <option value="80000">£80,000</option>
                                    <option value="90000">£90,000</option>
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={maxPrice}
                                    onChange={(e) =>
                                      setmaxPrice(e.target.value)
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option>Max Price</option>
                                    <option value="5000">£5,000</option>
                                    <option value="10000">£10,000</option>
                                    <option value="20000">£20,000</option>
                                    <option value="30000">£30,000</option>
                                    <option value="40000">£40,000</option>
                                    <option value="50000">£50,000</option>
                                    <option value="60000">£60,000</option>
                                    <option value="70000">£70,000</option>
                                    <option value="80000">£80,000</option>
                                    <option value="90000">£90,000</option>
                                    <option value="100000">£100,000</option>
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={distance}
                                    onChange={(e) =>
                                      setDistance(e.target.value)
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option>Distance</option>
                                    <option value="10000">National</option>
                                    <option value="10">Within 10 miles</option>
                                    <option value="20">Within 20 miles</option>
                                    <option value="30">Within 30 miles</option>
                                    <option value="40">Within 40 miles</option>
                                    <option value="50">Within 50 miles</option>
                                    <option value="60">Within 60 miles</option>
                                    <option value="70">Within 70 miles</option>
                                    <option value="80">Within 80 miles</option>
                                    <option value="90">Within 90 miles</option>
                                    <option value="100">
                                      Within 100 miles
                                    </option>
                                    <option value="200">
                                      Within 200 miles
                                    </option>
                                  </select>
                                </div>
                                <div className="col-6 mb-3">
                                  <select
                                    className="form-select"
                                    value={body_type}
                                    onChange={(e) =>
                                      setBodyType(e.target.value)
                                    }
                                  >
                                    <option>Body Type</option>
                                    {bodTypes.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item?.body_type}
                                      >
                                        {item?.body_type}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {showDiv && (
                                  <div className="col-12 mb-4">
                                    <div className="row more_filter">
                                      <div className="card mb-3">
                                        <div className="card-body">
                                          <h5 className="fs-6 mb-3 fw-semibold">
                                            Gearbox
                                          </h5>
                                          <div className="d-flex justify-content-between">
                                            {grearBoxs.map((item, index) => (
                                              <div class="form-check d-flex align-items-center">
                                                <input
                                                  className="form-check-input me-2"
                                                  type="checkbox"
                                                  onChange={updateURL}
                                                  name="gearbox[]"
                                                  value={
                                                    item?.transmission_type
                                                  }
                                                  key={index}
                                                />
                                                <label
                                                  className="form-check-label text-capitalize mb-0 pb-0"
                                                  for={`gearbox ` + index}
                                                >
                                                  {item?.transmission_type}
                                                </label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card mb-3">
                                        <div className="card-body">
                                          <h5 className="fs-6 mb-1 fw-semibold">
                                            Fuel Type
                                          </h5>
                                          <div className="col-12">
                                            <div className="row">
                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="petrol"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Petrol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="diesel"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Diesel
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="bi fuel"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Bi Fuel
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="elecric"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Elecric
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="diesel hybrid"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Diesel Hybrid
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-6 col-sm-4 mt-3">
                                                <div class="form-check d-flex align-items-center">
                                                  <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    onChange={updateURL}
                                                    name="fueltype[]"
                                                    value="petrol hybrid"
                                                  />
                                                  <label
                                                    className="form-check-label mb-0 pb-0"
                                                    for="defaultCheck1"
                                                  >
                                                    Petrol Hybrid
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card mb-3">
                                        <div className="card-body">
                                          <h5 className="fs-6 mb-3 fw-semibold">
                                            Engine Size
                                          </h5>
                                          <div className="col-12">
                                            <div className="form-group">
                                              <select
                                                onChange={(e)=>{
                                                  setEngineSize(e.target.value)
                                                }}
                                                value={engineSize}
                                                className="form-select"
                                                name="engine_size"
                                              >
                                                <option value="">
                                                  Select Size
                                                </option>
                                                {generateOptions().map(
                                                  (esize) => (
                                                    <option
                                                      key={esize}
                                                      value={esize}
                                                    >
                                                      {esize}L
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card ">
                                        <div className="card-body">
                                          <h5 className="fs-6 mb-1 fw-semibold">
                                            Colour
                                          </h5>
                                          <div className="col-12">
                                            <div className="row">
                                              {allColors.map((item, index) => (
                                                <div className="col-6 col-sm-4 mt-3">
                                                  <div class="form-check d-flex align-items-center">
                                                    <input
                                                      className="form-check-input me-2"
                                                      type="checkbox"
                                                      onChange={updateURL}
                                                      name="colors[]"
                                                      value={item?.color}
                                                      key={index}
                                                    />
                                                    <label
                                                      className="form-check-label text-capitalize mb-0 pb-0"
                                                      for={`color ` + index}
                                                    >
                                                      {item?.color}
                                                    </label>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="d-flex justify-content-end">
                                  <Link
                                    to={`/FilterCarData?distance=${distance}&min_price=${minPrice}&max_price=${maxPrice}&make=${name}&model=${model}&min_mileage=${minMileage}&max_mileage=${maxMileage}&minYear=${minYear}&maxYear=${maxYear}&model_variant=${model_varient}&body_type=${body_type}&gearbox=${gearbox}&fuel_type=${fuelType}&colors=${colors}&engine_size=${engineSize}`}
                                    className="btn btn-primary"
                                  >
                                    SEARCH
                                  </Link>
                                </div>

                                <div className="d-flex mt-2 justify-content-between more_options">
                                  <button
                                    className="nav-link fs-7 text-uppercase"
                                    type="reset"
                                    onClick={resetFilter}
                                  >
                                    Reset Filters
                                    
                                  </button>
                                  <button
                                    type="button"
                                    className="nav-link fs-7 text-uppercase"
                                    onClick={toggleDiv}
                                  >
                                    More Options
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HowToUse />
        <About />

        <section className="all_faqs container py-3">
          <h2 className="py-2">FAQ</h2>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Why we use price ranges?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  We use price ranges to reduce the need of reducing prices if
                  your car isn’t selling as this can put the seller at a
                  disadvantage, and to also allow the buyers to make reasonable
                  and fair offers.
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Is there a time limit on how long a car can be listed for?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  There is no time limit, however you will receive an email
                  every few months to confirm you still want your car listed, if
                  we do not receive a reply after attempts to contact you, your
                  car may be removed to remove clutter on the website.
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How many cars can I list
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  You can list a maximum of 5 cars per account
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Can I get a refund for my credits
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  No, we do not provide refunds for unwanted credits
                </div>
              </div>
            </div>
          </div>
        </section>
        {profile || storedUserPreferences != null ? (
          ""
        ) : (
          <section className="login container d-flex flex-column justify-content-center  py-4 py-md-5 ">
            <div className="d-flex flex-column justify-content-center align-items-center  w-100">
              <h2 className="fw-bold mb-2">Login / Sign Up Here</h2>
              <p className="text-center mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laboriosam, reiciendis odio doloremque ut temporibus placeat eum
                beatae molestias non excepturi harum voluptates officiis
              </p>
              <div className="col-lg-9 col-md-11 col-11 mx-auto">
                <div className="d-flex flex-column justify-content-center align-items-center rounded-3 p-3 p-md-5 border-0">
                  <h4 className="text-center">GET STARTED</h4>
                  <p className="text-center">Already have an account?</p>
                  <Link
                    to="/login"
                    className="btn btn-primary w-50  d-flex align-items-center justify-content-center m-2 rounded-pill py-2"
                  >
                    LOGIN
                  </Link>
                  <Link
                    to="/register"
                    className="btn btn-primary w-50  d-flex align-items-center justify-content-center m-2 rounded-pill py-2"
                  >
                    SIGN UP
                  </Link>
                  <div className=""></div>

                  <div className="mt-3 other_media d-flex justify-content-center">
                    <span className="text-center">Or Continue With</span>
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <GoogleOAuthProvider clientId={REACT_APP_GG_APP_ID}>
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        size="large"
                        type="icon"
                        shape="pill"
                      />
                    </GoogleOAuthProvider>

                    <span className="applebtn">
                      <img src="img/apple.png" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default HomePage;
