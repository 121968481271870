import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "../Footer";
import axios from "axios";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import { jwtDecode } from "jwt-decode";

const ListMyCar = () => {
  const [profile, setProfile] = useState(null);
  const navigator = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const getDataFromSessionStorage = (key) => {
    try {
      const serializedData = sessionStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from sessionStorage:", error);
      return null;
    }
  };
  const REACT_APP_GG_APP_ID =
    "1053333615945-mnk7qqgqtr1gmmd2gj0j03p9itlp198f.apps.googleusercontent.com";

  const saveDataToSessionStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      sessionStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to sessionStorage:", error);
    }
  };
  const loginWithGoogle = async (email, name, profile) => {
    try {
      const response = await axios.post(
        "https://trustedcars.in/api/public/api/login-google/user",
        {
          email: email,
          name: name,
          profile: profile,
        }
      );
      //  console.log(response?.data);
      if (response?.data?.status) {
        saveDataToSessionStorage("userData", response?.data?.data);
        showAlert(
          response?.data?.message,
          response?.data?.data?.id,
          response?.data?.status
        );
      } else {
        toast.error(response?.data?.message, optionToast);
        Navigate("/signin");
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const showAlert = (data, id, show) => {
    if (show) {
      toast.success(data, optionToast);
      setTimeout(() => {
        navigator("/home");
      }, 1500);
    } else {
      toast.error(data, optionToast);
    }
  };

  // Example usage:
  const storedUserPreferences = getDataFromSessionStorage("userData");

  const handleGoogleLoginSuccess = async (response) => {
    const { credential } = response;
    const decodedToken = jwtDecode(credential);
    if (decodedToken !== null) {
      const email = decodedToken?.email;
      const name = decodedToken?.name;
      const profilePicture = decodedToken?.picture;
      loginWithGoogle(email, name, profilePicture);
      setProfile(decodedToken);
    } else {
      toast.error("Unable to get profile", optionToast);
    }
  };
  return (
    <>
      <Navbar />
      <div className="mainbanner">

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-8 col-xl-6 col-md-12 mt-5">
            <div className="card border-0 bg-white mt-3">
              <div className="card-body pt-2 pt-md-3 p-0 p-md-4">
                <div className="search_area ">
                  <div className="d-flex justify-content-between py-2">
                    <Link
                      to="/"
                      type="button"
                      id="btn1"
                      className="btn btn-light w-100 mb-2 mx-2"
                    >
                      Find My Car
                    </Link>
                    <Link
                      to="/listmycar"
                      id="btn2"
                      className="btn btn-primary w-100 mb-2 mx-2"
                    >
                      List My Car
                    </Link>
                  </div>

                  <div className="card mx-2 mb-3">
                    <div className="card-body p-0 p-lg-3 py-3 px-2">
                      <h3 className="pb-2 fw-bold text-center m-0">
                        List, <span className="blue">Find</span>, Save
                      </h3>
                      <h4 className="pb-2 fw-bold text-center">
                        Enter the Car Reg to get started.
                      </h4>
                      <h6 className="fw-bold text-center">
                        Save money with <span className="blue">Carloca -</span>{" "}
                        it's <span className="blue">FREE</span> to list your car
                        !
                      </h6>
                      <div className="d-flex justify-content-center mt-3">
                        <Link to={"/listdetails"} className="btn btn-primary ">
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <div id='filters' className="filters mt-2 search_area container ">
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="new_page">
        <section className=" container d-flex flex-column py-3 py-md-5">
          <h2 className=" fw-bold mb-3 text-center">
            How to Use Carloca as a <span className="blue">Seller</span>
          </h2>

          <div className="d-flex w-100 justify-content-center how_use py-3 py-md-5 rounded-3">
            <div className="row w-100">
              <div className="col-md-3 col-sm-6">
                <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                  <Link className="text-center">
                    <i className="fa-solid fa-mobile fs-1"></i>
                    <h5 className="fw-bold mt-2">List</h5>
                  </Link>
                  <p className="text-center mb-0">
                    All you need to do is fill in the basics of the car and our
                    DLVA system will auto-populate the rest of the car details.
                    Remember to mention any specifications -this can make your
                    car stand out amongst others.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                  <Link className="text-center">
                    <i className="fa-solid fa-bell"></i>
                    <h5 className="fw-bold mt-2">Receive Offers</h5>
                  </Link>
                  <p className="text-center mb-0">
                    You'll be notified that you've received offers on your
                    listed car, you can view the offers and choose to accept or
                    deny the request
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                  <Link className="text-center">
                    <i className="fa-solid fa-thumbs-up"></i>
                    <h5 className="fw-bold mt-2">Approve/Deny</h5>
                  </Link>
                  <p className="text-center mb-0">
                    When you accept the request, you will unlock the
                    communication between yourself and the prospective buyer.
                    Please be aware that when you accept the offer, a buyers
                    credit will be deducted.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="d-flex flex-column justify-content-center align-items-center mb-4 px-2">
                  <Link className="text-center">
                    <i className="fa-solid fa-comment-dots"></i>
                    <h5 className="fw-bold mt-2">Chat</h5>
                  </Link>
                  <p className="text-center mb-0">
                    Using the unlocked contact details, you'll be able to talk
                    about the offer made and make arrangements to sell your car.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about container d-flex flex-column mt-3 py-3">
          <div className="row justify-content-center  mt-5">
            <div className=" col-lg-7 col-sm-6  p-md-3">
              <div className="">
                <h2 className=" pb-3 fw-bold">
                  About <span className="blue">Carloca</span>
                </h2>

                <p className="m-0">
                  Our premier online platform is for car enthusiasts and
                  sellers. We understand the importance of trust, great offers
                  and finding cars with certain specifications.
                </p>
                <p className="m-0">
                  That's why our platform is designed to offer a
                  straightforward, secure, and efficient experience like no
                  other
                </p>
              </div>
              <div className=" mt-3 mb-3">
                <h5 className="fw-bold">
                  Tired of <span className="blue">cold calls</span> and{" "}
                  <span className="blue">time wasters?</span>
                </h5>
                <p className="m-0">
                  Carloca is the genuine platform addresses just that by using a
                  credit system which the lister has full control over.{" "}
                </p>
                <p className="m-0">
                  Whether you're looking to invest in a new car or position
                  yours for sale, Carloca stands as your reliable partner.
                </p>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-4 col-sm-6 p-md-3">
              <img
                src="img/how_use1.jpg.png"
                className="w-100 h-100 mb-3"
                alt=""
              />
            </div>
          </div>
        </section>

        <section className="all_faqs container py-3">
          <h2 className="py-2">FAQ</h2>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Why we use price ranges?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  We use price ranges to reduce the need of reducing prices if
                  your car isn’t selling as this can put the seller at a
                  disadvantage, and to also allow the buyers to make reasonable
                  and fair offers.
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Is there a time limit on how long a car can be listed for?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  There is no time limit, however you will receive an email
                  every few months to confirm you still want your car listed, if
                  we do not receive a reply after attempts to contact you, your
                  car may be removed to remove clutter on the website.
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How many cars can I list
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  You can list a maximum of 5 cars per account
                </div>
              </div>
            </div>
            <div className="accordion-item my-2">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Can I get a refund for my credits
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  No, we do not provide refunds for unwanted credits
                </div>
              </div>
            </div>
          </div>
        </section>
        {profile || storedUserPreferences != null ? (
          ""
        ) : (
          <section className="login container d-flex flex-column justify-content-center  py-4 py-md-5 ">
            <div className="d-flex flex-column justify-content-center align-items-center  w-100">
              <h2 className="fw-bold mb-2">Login / Sign Up Here</h2>
              <p className="text-center mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laboriosam, reiciendis odio doloremque ut temporibus placeat eum
                beatae molestias non excepturi harum voluptates officiis
              </p>
              <div className="col-lg-9 col-md-11 col-11 mx-auto">
                <div className="d-flex flex-column justify-content-center align-items-center rounded-3 p-3 p-md-5 border-0">
                  <h4 className="text-center">GET STARTED</h4>
                  <p className="text-center">Already have an account?</p>
                  <Link
                    to="/login"
                    className="btn btn-primary w-50 d-flex align-items-center justify-content-center m-2 rounded-pill py-2"
                  >
                    LOGIN
                  </Link>
                  <Link
                    to="/register"
                    className="btn btn-primary w-50 d-flex align-items-center justify-content-center m-2 rounded-pill py-2"
                  >
                    SIGN UP
                  </Link>
                  <div className=""></div>
                  <div className="mt-3 other_media d-flex justify-content-center">
                    <span className="text-center">Or Continue With</span>
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <GoogleOAuthProvider clientId={REACT_APP_GG_APP_ID}>
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        size="large"
                        type="icon"
                        shape="pill"
                      />
                    </GoogleOAuthProvider>

                    <span className="applebtn">
                      <img src="img/apple.png" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <Footer />

      <ToastContainer />
    </>
  );
};

export default ListMyCar;
